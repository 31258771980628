<template>
  <div class="row">

    <div class="col-md-4">
      <div class="card">
        <div class="card-header bg-navyblue">
          <strong>Invoice To</strong>
        </div>
        <div class="card-block bg-white">

          <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">Invoice Number</label>
            <div class="col-sm-8 col-8">
              <input type="text" class="form-control" v-model="invoice.number" readonly>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">Invoice Date</label>
            <div class="col-sm-8 col-8">
              <b-form-datepicker
                  id="invoiceDate"
                  class="mb-2"
                  locale="en-GB"
                  :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                  v-model="invoice.dateInvoice"
                  :disabled="isUserRoleLawyer"
              ></b-form-datepicker>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">Bill To</label>
            <div class="col-sm-8 col-8">
              <input type="text" class="form-control" v-model="invoice.vehicleOwner" readonly>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">Bill To Address</label>
            <div class="col-sm-8 col-8">
              <textarea class="form-control" rows="3" readonly></textarea>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-4 col-form-label">Due Date</label>
            <div class="col-sm-8 col-8">
              <b-form-datepicker
                  id="invoiceDueDate"
                  class="mb-2"
                  locale="en-GB"
                  :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                  v-model="invoice.dueDate"
                  :disabled="isUserRoleLawyer"
              ></b-form-datepicker>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-md-8">
      <div class="card">
        <div class="card-header bg-navyblue">
          <strong style="visibility: hidden">&nbsp;.</strong>
        </div>
        <div class="card-block bg-white">

          <table class="invoice-table">
            <colgroup>
              <col class="col-wide">
              <col class="col-tight">
              <col class="col-tight">
              <col class="col-tight">
            </colgroup>
            <thead>
            <tr>
              <th>Description</th>
              <th>Qty</th>
              <th>Unit Price</th>
              <th>Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr class="body">
              <td class="p-0">
                <textarea rows="8" class="form-control" v-model="invoice.description" :disabled="isUserRoleLawyer"></textarea>
              </td>
              <td>1</td>
              <td>{{ invoice.subtotal | formatMoney }}</td>
              <td>{{ invoice.subtotal | formatMoney }}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <th class="bb">Subtotal</th>
              <td class="bb">{{ invoice.subtotal | formatMoney }}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <th class="bb">GST</th>
              <td class="bb">{{ invoice.gst | formatMoney }}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <th class="bb">Total</th>
              <td class="bb">{{ invoice.total | formatMoney }}</td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import NumberFormatter from "@/components/utility/number-formatter";
import {mapGetters} from "vuex";

export default {
  name: "details",
  components: {
    NumberFormatter,
  },
  computed: {
    ...mapGetters({
      isUserRoleLawyer: 'currentUser/isRoleLawyer',
    })
  },
  props: {
    invoice: {
      type: [Object, null],
      default(){
        return {
          id: 0,
          number: '',
          claimNumber: '',
          assessmentId: 0,
          assessmentNumber: '',
          vehicleOwner: '',
          createdOn: '',
          dueDate: '',
          dateInvoice: '',
          paid: 0,
          due: 0,
          status: '',
          description: '',
          xeroInvoiceNumber: '',
        };
      }
    },
  }
}
</script>

<style scoped>

.invoice-table {
  width: 100%;
}
.invoice-table td,
.invoice-table th {
  padding: 5px;
  height: auto;
  vertical-align: top;
}
.invoice-table .body {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}
.invoice-table tr.body td {
  border-right: 1px solid lightgray;
}
.invoice-table tr td:last-child {
  border-right: none;
}
.invoice-table tr td.bb,
.invoice-table tr th.bb {
  border-bottom: 1px solid lightgray;
}
.invoice-table tr th.bb {
  text-align: right;
}
.invoice-table .col-wide {

}
.invoice-table .col-tight {
  width: 15%;
}

</style>
